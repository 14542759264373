/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './layout/**/*.liquid',
    './sections/**/*.liquid',
    './snippets/**/*.liquid',
    './templates/**/*.liquid',
    './src/scripts/**/*.{js,jsx}',
    './assets/**/*.js',
  ],
  safelist: [
    'md:col-span-1',
    'md:col-span-2',
    'md:col-span-3',
    'md:col-span-4',
    'md:col-span-5',
    'md:col-span-6',
    'md:col-span-7',
    'md:col-span-8',
    'md:col-span-9',
    'md:col-span-10',
    'md:col-span-11',
    'md:col-span-12',
    'lg:col-span-1',
    'lg:col-span-2',
    'lg:col-span-3',
    'lg:col-span-4',
    'lg:col-span-5',
    'lg:col-span-6',
    'lg:col-span-7',
    'lg:col-span-8',
    'lg:col-span-9',
    'lg:col-span-10',
    'lg:col-span-11',
    'lg:col-span-12',
  ],
  theme: {
    colors: {
      primary: '#1D1D1D',
      grey: { 2: '#757575', 3: '#b2b2b2', 4: '#F8F8F8' },
      transparent: 'transparent',
      white: 'white',
      current: 'currentColor',
      success: '#4cc258',
      warning: '#f48e2f',
      error: '#f4522f',
    },
    fontFamily: {
      sans: '"Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
    },
    screens: {
      sm: '576px',
      md: '820px',
      lg: '1024px',
      xl: '1366px',
    },
    extend: {
      aspectRatio: {
        'product-item': '2 / 3',
        fullscreen: '4 / 3',
      },
      spacing: {
        15: '3.75rem',
      },
      gridTemplateColumns: {
        'flexible-6': 'repeat(6, 1fr)',
        'flexible-12': 'repeat(12, 1fr)',
        13: 'repeat(13, minmax(0, 1fr))',
      },
      maxHeight: {
        112: '28rem',
        128: '32rem',
      },
      zIndex: {
        '-1': '-1',
      },
    },
  },
  plugins: [require('@tailwindcss/forms')],
};
