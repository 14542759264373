import Splide from '@splidejs/splide';
import { unitlessBreakpoints } from './breakpoints';

const selectors = {
  mainCarousel: '[data-splide-carousel]',
};

const initCarousel = (el, options = {}) => {
  const carousel = el.querySelector(selectors.mainCarousel);

  if (carousel) {
    const carouselOptions = {
      perPage: 2,
      perMove: 1,
      gap: '4px',
      arrows: false,
      pagination: true,
      classes: {
        pagination:
          'splide__pagination splide__pagination--black opacity-50 my-6',
      },
      mediaQuery: 'min',
      breakpoints: {
        [unitlessBreakpoints.md]: {
          perPage: 3,
        },
        [unitlessBreakpoints.lg]: {
          perPage: 4,
        },
      },
      ...options,
    };

    new Splide(carousel, carouselOptions).mount();

    // if (splide.length <= splide.options.perPage) {
    //   splide.options = {
    //     arrows: false,
    //   };
    // }

    // splide.on('hidden', () => {
    //   if (splide.length > splide.options.perPage) {
    //     splide.options = {
    //       arrows: true,
    //     };
    //   }
    // });
    // splide.on('visible', () => {
    //   if (splide.length <= splide.options.perPage) {
    //     splide.options = {
    //       arrows: false,
    //     };
    //   }
    // });
  }
};

export default initCarousel;
