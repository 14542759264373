import Product from './Product';

const selectors = {
  complementaryProductLink: '[data-comp-product-link]',
  product: '[data-product]',
};

/**
 * Async product switching functionality
 *
 * @export
 * @class ProductAsyncSwitcher
 */
export default class ProductAsyncSwitcher {
  constructor(el, sectionId) {
    this.el = el;
    this.sectionId = sectionId;

    const complementaryProductLinks = el.querySelectorAll(
      selectors.complementaryProductLink,
    );

    complementaryProductLinks.forEach(link => {
      const handle = link.dataset.compProductLink;
      if (handle) {
        link.addEventListener('click', e => {
          e.preventDefault();
          this._updateURL(handle);
          this._fetchProductByHandle(handle);
        });
      }
    });
  }

  _fetchProductByHandle(handle) {
    fetch(
      `${window.Shopify.routes.root}products/${handle}?section_id=${this.sectionId}`,
    )
      .then(response => response.text())
      .then(responseText => {
        const html = responseText;
        const parsedHTML = new DOMParser().parseFromString(html, 'text/html');
        const newRoot = parsedHTML.querySelector(selectors.product);

        this.el.innerHTML = newRoot.innerHTML;
        new Product(this.el, {}, this.sectionId);
      });
  }

  _updateURL(handle) {
    history.pushState(
      {},
      '',
      `${window.Shopify.routes.root}products/${handle}`,
    );
  }
}
