import { formatMoney } from '@shopify/theme-currency';
import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';
import { useState } from 'preact/hooks';

const CartCrossSellProduct = ({ product }) => {
  if (!product) {
    return null;
  }

  const { addLineItem } = useCartContext();
  const [selectedVariant, setSelectedVariant] = useState(product.variants[0]);

  const cross_sell_image = product.images[0] ? product.images[0] : '';

  const validOptions = [];
  product.variants.map(variant => {
    validOptions.push(
      ...variant.options.filter(option => !validOptions.includes(option)),
    );
  });

  const handleOptionChange = option => {
    const variantSelected = product.variants.find(variant =>
      variant.options.includes(option),
    );
    setSelectedVariant(variantSelected);
  };
  if (!selectedVariant || !selectedVariant.id) {
    return null;
  }

  const handleAdd = id => {
    addLineItem({
      items: [
        {
          id,
          quantity: 1,
        },
      ],
    });
  };

  return (
    <div
      class="flex items-start space-x-4 border border-primary bg-primary p-4"
      data-product-id={product.id}
    >
      <div class="aspect-product-item w-16 bg-white">
        <img src={cross_sell_image} class=" h-full w-full object-cover" />
      </div>
      <div class="flex-1 md:flex md:items-start">
        <div class="flex-1">
          <h5 class="type-text-8 mb-1 text-primary">
            {getTranslation('mini_cart.add_on')}
          </h5>
          <h4 class="type-text-5">{product.title}</h4>
          <div class="type-text-7 mb-2 mt-1 flex flex-row items-center">
            <span>{formatMoney(selectedVariant.price, theme.moneyFormat)}</span>
          </div>

          {product.options.length > 0 &&
            product.options.map(option => {
              const validValues = option.values.filter(value =>
                validOptions.includes(value),
              );

              if (validValues.length <= 1) {
                return '';
              }
              return (
                <select
                  name={`options[${option.name}]`}
                  class="type-text-8 mb-2 block w-full appearance-none sm:w-48 md:mb-0"
                  onChange={e => handleOptionChange(e.target.value)}
                >
                  {option.values.map((value, index) => {
                    return (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    );
                  })}
                </select>
              );
            })}
        </div>
        <button
          onClick={() => handleAdd(selectedVariant.id)}
          class="button button--primary w-full sm:w-48 md:w-auto"
          disabled={!selectedVariant.available}
        >
          {selectedVariant.available
            ? getTranslation('mini_cart.add_to_cart')
            : getTranslation('mini_cart.sold_out')}
        </button>
      </div>
    </div>
  );
};

export default CartCrossSellProduct;
