import EventEmitter from 'eventemitter3';
import { isKeyCode } from '../helpers/isKeyCode';

export default class Dropdown extends EventEmitter {
  /**
   * Dropdown Menu
   *
   * @param {object} el Javascript DOMElement Object that contains the dropdown elements.
   * @param {object} config Configuration options.
   * @param {string} config.triggerClass Class to apply to triggers. --open and --close will be appended to this class.
   * @param {string} config.contentClass Class to apply to content areas. --open and --close will be appended to this class.
   * @param {string} config.triggerDataAttribute The dropdown trigger data attribute
   * @param {string} config.contentDataAttribute The dropdown content data attribute
   */
  constructor(config = {}) {
    super();

    this.config = Object.assign(
      {
        el: document,
        triggerClass: 'dropdown-trigger',
        contentClass: 'dropdown-content',
        triggerDataAttribute: 'data-dropdown-trigger',
        contentDataAttribute: 'data-dropdown-content',
        closeOnClickOutside: true,
        closeOthers: true,
        focusOnOpen: true,
        focusOnClose: true,
      },
      config,
    );

    this.triggers = [
      ...this.config.el.querySelectorAll(
        `[${this.config.triggerDataAttribute}]`,
      ),
    ];

    this.contentAreas = [
      ...this.config.el.querySelectorAll(
        `[${this.config.contentDataAttribute}]`,
      ),
    ];

    if (!this.triggers.length || !this.contentAreas.length) {
      return;
    }

    this._init();
  }

  /**
   * Initialize events.
   *
   * @private
   */
  _init() {
    // Add click listeners to each of the triggers.
    this.triggers.forEach(trigger => {
      trigger.addEventListener(
        'click',
        this._handleTriggerClick(
          trigger.getAttribute(this.config.triggerDataAttribute),
        ),
      );
    });

    // Add keydown listeners to each of the content areas.
    this.contentAreas.forEach(content =>
      content.addEventListener(
        'keydown',
        this._handleContentKeyPress(
          content.getAttribute(this.config.contentDataAttribute),
        ),
      ),
    );
  }

  /**
   * Helper to find a specific trigger or content area element.
   *
   * @param {object} type - Type of data attribute to search for.
   * @param {string} value - Value of data attribute.
   */
  _getElement(type, value) {
    return this.config.el.querySelector(`[${type}="${value}"]`);
  }

  /**
   * Sets classes and accessibility attributes based on dropdown state.
   *
   * @param {object} trigger - JS DOMElement representing the trigger.
   * @param {object} content - JS DOMElement representing the content area.
   * @param {string} direction - The desired state of the dropdown. Must be either 'open' or 'closed'
   */
  _setClassesAndAttributes(trigger, content, direction) {
    const opposite = direction === 'open' ? 'closed' : 'open';

    // Set classes on trigger and content area.
    trigger.classList.remove(`${this.config.triggerClass}--${opposite}`);
    trigger.classList.add(`${this.config.triggerClass}--${direction}`);
    content.classList.remove(`${this.config.contentClass}--${opposite}`);
    content.classList.add(`${this.config.contentClass}--${direction}`);

    // Set accessibility attributes.
    content.setAttribute('aria-hidden', direction === 'closed');
    content.setAttribute('aria-expanded', direction === 'open');
  }

  /**
   * Open the drop down.
   *
   * @param {object} trigger - JS DOMElement representing the trigger.
   * @param {object} content - JS DOMElement representing the content area.
   */
  _open(trigger, content) {
    this._setClassesAndAttributes(trigger, content, 'open');

    // Add click outside listener if option is true in config.
    if (this.config.closeOnClickOutside) {
      document.addEventListener(
        'click',
        this._clickOutsideListener(trigger, content),
      );
    }

    // Close all other dropdowns if option is true in config.
    if (this.config.closeOthers) {
      this._closeOtherContent(trigger);
    }

    // Find focasable elements that tab-navigating should look for.
    if (this.config.focusOnOpen) {
      const focusableElements = content.querySelectorAll(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );

      // Focus on first focusable elements, if there are any.
      if (focusableElements.length) {
        focusableElements[0].focus();
      }
    }

    this.emit('open', trigger, content);
  }

  /**
   * Close the drop down.
   *
   * @param {object} trigger - JS DOMElement representing the trigger.
   * @param {object} content - JS DOMElement representing the content area.
   */
  _close(trigger, content) {
    this._setClassesAndAttributes(trigger, content, 'closed');

    // Remove click outside listener if option is true in config.
    if (this.config.closeOnClickOutside) {
      document.removeEventListener(
        'click',
        this._clickOutsideListener(trigger, content),
      );
    }

    // Focus on content area's trigger.
    if (this.config.focusOnClose) {
      trigger.focus();
    }

    this.emit('close', trigger, content);
  }

  /**
   * Handler for click events on trigger element
   *
   * @param {string} attributeValue - Value of trigger data attribute.
   * @param {object} event - Click event.
   */
  _handleTriggerClick = attributeValue => event => {
    event.stopPropagation();

    // Get trigger element.
    const trigger = this._getElement(
      this.config.triggerDataAttribute,
      attributeValue,
    );

    // Get content area element.
    const content = this._getElement(
      this.config.contentDataAttribute,
      attributeValue,
    );

    // Bail if no matching content area.
    if (!content) {
      // eslint-disable-next-line no-console
      console.error(
        `No content area with matching ${this.config.contentDataAttribute} value`,
      );
      return;
    }

    // Toggle open and close of the trigger and content.
    if (content.classList.contains(`${this.config.contentClass}--open`)) {
      this._close(trigger, content);
    } else {
      this._open(trigger, content);
    }
  };

  /**
   * Handler for keyboard events on content area element
   *
   * @param {string} attributeValue - Value of content area data attribute.
   * @param {object} event - Keyboard event.
   */
  _handleContentKeyPress = attributeValue => event => {
    event.stopPropagation();

    // Get trigger element.
    const trigger = this._getElement(
      this.config.triggerDataAttribute,
      attributeValue,
    );

    // Get content area element.
    const content = this._getElement(
      this.config.contentDataAttribute,
      attributeValue,
    );

    // Bail if no matching trigger.
    if (!trigger) {
      // eslint-disable-next-line no-console
      console.error(
        `No trigger with matching ${this.config.triggerDataAttribute} value`,
      );
      return;
    }

    // Toggle open and close of the trigger and content if escape key is pressed.
    const key = event.key || event.keyCode;
    if (key === 'Escape' || key === 'Esc' || isKeyCode(event, 'escape')) {
      this._close(trigger, content);
    }
  };

  /**
   * Listener for clicking outside the opened menu.
   *
   * @param {object} trigger - JS DOMElement representing the trigger.
   * @param {object} content - JS DOMElement representing the content area.
   * @param {object} event - Click event.
   */
  _clickOutsideListener = (trigger, content) => event => {
    if (!content.contains(event.target)) {
      this._close(trigger, content);
    }
  };

  /**
   * Handler for closing other content areas.
   *
   * @param {object} currentTrigger - JS DOMElement representing the trigger being opened.
   */
  _closeOtherContent(currentTrigger) {
    // Filter out current trigger and then close the appropriate triggers and content based on data attribute values.
    this.triggers
      .filter(
        trigger =>
          trigger.getAttribute(this.config.triggerDataAttribute) !==
          currentTrigger.getAttribute(this.config.triggerDataAttribute),
      )
      .forEach(trigger => {
        const content = document.querySelector(
          `[${this.config.contentDataAttribute}="${trigger.getAttribute(
            this.config.triggerDataAttribute,
          )}"]`,
        );
        this._close(trigger, content);
      });
  }

  /**
   * Unload the Dropdown.
   */
  _unload() {
    // Remove click listeners from each of the triggers.
    this.triggers.forEach(trigger => {
      trigger.removeEventListener(
        'click',
        this._handleTriggerClick(
          trigger.getAttribute(this.config.triggerDataAttribute),
        ),
      );
    });

    // Remove keydown listeners from each of the content areas.
    this.contentAreas.forEach(content =>
      content.removeEventListener(
        'keydown',
        this._handleContentKeyPress(
          content.getAttribute(this.config.contentDataAttribute),
        ),
      ),
    );
  }
}
