import * as cartApi from '../../helpers/cartApi';
import initCarousel from '../../helpers/carousel';
import { updateReactCart } from '../../preact/helpers/utilities';

const selectors = {
  form: '[data-product-upsell-form]',
};

/**
 * Configuration of the PDP Upsell
 *
 * @export
 * @class ProductUpsell
 */
export default class ProductUpsell {
  /**
   * Creates an instance of ProductUpsell.
   * @param {HTMLElement} productContainer The container of this product
   */
  constructor(productContainer) {
    this.el = productContainer;
    this.formElements = this.el.querySelectorAll(selectors.form);

    if (this.formElements) {
      this.formElements.forEach(form => {
        form.addEventListener('submit', this._onFormSubmit);
      });
    }

    initCarousel(productContainer, {
      perPage: 1,
      classes: {
        pagination:
          'splide__pagination splide__pagination--small splide__pagination--black opacity-50 mt-2',
      },
      breakpoints: {},
    });
  }

  _onFormSubmit = event => {
    event.preventDefault();

    cartApi
      .add(event.target)
      .then(() => {
        updateReactCart(true);
      })
      .catch(response => this._handleCartApiError(response));
  };
}
