import { createPortal } from 'preact/compat';
import { getTranslation } from '../helpers/utilities';
import { useCartContext } from '../context/CartContext';
import useEmptyPortal from '../hooks/useEmptyPortal';

import { h } from 'preact';

const MiniCartEl = document.getElementById('preact-mini-cart');

const MiniCart = () => {
  const { cart, toggleMiniCart } = useCartContext();
  const [emptied] = useEmptyPortal(MiniCartEl);

  if (!emptied) {
    return null;
  }

  return createPortal(
    <button
      class="type-text-9 relative block"
      onClick={() => {
        toggleMiniCart(true);
      }}
    >
      {getTranslation('header.cart')} ({cart.item_count})
    </button>,
    MiniCartEl,
  );
};

export default MiniCart;
