import CartItemPrice from './CartItemPrice';
import CartRemove from './CartRemove';
import QuantitySelector from '../shared-components/QuantitySelector';
import { formatMoney } from '@shopify/theme-currency';
import { getSizedImageUrl } from '@shopify/theme-images';
import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';
import { useState } from 'preact/hooks';

const CartItem = ({ item }) => {
  const [error, setError] = useState();

  const titleParts = item.title.split(':');
  const productTitle = titleParts[0];
  const variantTitle = titleParts[1];

  return (
    <div class="relative flex border-b border-primary py-5 md:py-7">
      {item.featured_image && item.featured_image.url && (
        <div class="mr-3 w-20">
          <a class="block aspect-product-item" href={item.url}>
            <img
              class="h-full w-full object-cover"
              alt={item.featured_image.alt}
              src={getSizedImageUrl(item.featured_image.url, 'medium')}
            />
          </a>
        </div>
      )}

      <div class="flex-1">
        <div class="md:flex md:items-start">
          <div class="flex-1">
            <a class="type-text-7 mb-1 block" href={item.url}>
              {productTitle}
            </a>
            <span class="type-text-9 block">{variantTitle}</span>
            <div class="mt-1 flex flex-wrap">
              <span class="type-text-9 mb-1 basis-full md:mb-0 md:flex-grow-0 md:basis-0">
                {item.price > item.final_price && (
                  <span class="mr-2 text-primary line-through">
                    {formatMoney(item.price, item.currency)}
                  </span>
                )}
                <span class="text-primary">
                  {formatMoney(item.final_price, theme.moneyFormat)}
                </span>
              </span>
            </div>
          </div>

          {item.properties &&
            Object.entries(item.properties).map(
              ([key, val]) =>
                val &&
                !key.startsWith('_') && (
                  <p>
                    {key}:{' '}
                    {val.includes('/uploads/') ? (
                      <a href={val}>{val.split('/').pop()}</a>
                    ) : (
                      val
                    )}
                  </p>
                ),
            )}

          <div class="md:flex md:flex-row-reverse md:items-center">
            <div class="mb-2 ml-4 hidden md:mb-0 md:ml-10 md:block">
              <CartItemPrice item={item} />
            </div>
            <QuantitySelector item={item} setError={setError} />
          </div>
        </div>

        <CartRemove item={item} />

        {/* Discounts */}
        {item.line_level_discount_allocations.length > 0 && (
          <div class="mt-2 space-y-2">
            {Object.entries(item.line_level_discount_allocations).map(
              ([key, value]) => (
                <div key={key} class="type-text-8">
                  {value.discount_application.title}
                </div>
              ),
            )}
          </div>
        )}
      </div>

      {error && (
        <div class="type-text-8 absolute bottom-1 right-0 text-error">
          {getTranslation('cart.quantity_error', { quantity: error })}
        </div>
      )}
    </div>
  );
};

export default CartItem;
