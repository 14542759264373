import DynamicFeaturedCollection from '../scripts/sections/DynamicFeaturedCollection';
import DynamicMediaWithText from '../scripts/sections/DynamicMediaWithText';

// Sections
import MainAddresses from '../scripts/sections/MainAddresses';
import MainCollection from '../scripts/sections/MainCollection';
import MainFaq from '../scripts/sections/MainFaq';
import MainHeader from '../scripts/sections/MainHeader';
import MainProduct from '../scripts/sections/MainProduct';
import MainProductRecommendations from '../scripts/sections/MainProductRecommendations';
import MainSearch from '../scripts/sections/MainSearch';
import Sections from '../scripts/helpers/SectionManager';

// React
import mountPreact from '../scripts/preact/mountPreact';

// Init global sections
const sections = new Sections();
sections.register('main-collection', section => new MainCollection(section));
sections.register('main-product', section => new MainProduct(section));
sections.register('main-addresses', section => new MainAddresses(section));
sections.register('main-header', section => new MainHeader(section));
sections.register('main-faq', section => new MainFaq(section));

sections.register(
  'dynamic-featured-collection',
  section => new DynamicFeaturedCollection(section),
  { lazy: true },
);

sections.register(
  'dynamic-media-w-text',
  section => new DynamicMediaWithText(section),
  { lazy: true },
);

sections.register(
  'main-product-recommendations',
  section => new MainProductRecommendations(section),
  {
    lazy: true,
  },
);

sections.register('main-search', section => new MainSearch(section));

// Init preact
mountPreact();
