import Splide from '@splidejs/splide';
import { pixelBreakpoints, unitlessBreakpoints } from '../../helpers';

const selectors = {
  productImage: '[data-product-image]',
  mainCarousel: '[data-image-carousel]',
  scrollingDots: '[data-scrolling-dots]',
  scrollingSentinel: '[data-scrolling-sentinel]',
};

/**
 * Product image gallery on the PDP
 *
 * @export
 * @class ProductImages
 */
export default class ProductImages {
  constructor(el) {
    this.mainCarousel = el.querySelector(selectors.mainCarousel);
    this.navItems = this.mainCarousel.querySelectorAll(selectors.productImage);
    this.scrollingDots = el.querySelectorAll(selectors.scrollingDots);
    this._initCarousel();

    this._imageScroller();
  }

  _imageScroller() {
    const observer = new IntersectionObserver(entries => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        const { imageIndex } = entry.target.dataset;
        this.scrollingDots.forEach((dot, i) => {
          if (+imageIndex === i) {
            dot.classList.add('bg-primary');
            dot.classList.remove('bg-transparent');
          } else {
            dot.classList.add('bg-transparent');
            dot.classList.remove('bg-primary');
          }
        });
      }
    });

    this.navItems.forEach(element => {
      const sentinel = element.querySelector(selectors.scrollingSentinel);
      observer.observe(sentinel);
    });
  }

  /**
   * Initialize primary image carousel
   */
  _initCarousel() {
    this.mainCarousel = new Splide(this.mainCarousel, {
      perPage: 1,
      pagination: true,
      arrows: false,
      classes: {
        pagination:
          'splide__pagination splide__pagination--black absolute bottom-5 inset-x-4 opacity-50',
      },
      mediaQuery: 'min',
      breakpoints: {
        [unitlessBreakpoints.md]: {
          destroy: true,
        },
      },
    });

    this.mainCarousel.mount();
  }

  /**
   * Handler for swapping images when the variant changes
   *
   * @param {Object} variant The currently selected variant
   * @public
   */
  change(variant) {
    const mediaQuery = window.matchMedia(
      `(min-width: ${unitlessBreakpoints.md})`,
    );

    this.navItems.forEach((element, index) => {
      const { imageVariants } = element.dataset;
      // Bail if array of variants for this image
      // doesn't contain currently selected variant id
      if (!imageVariants.split(',').includes(String(variant.id))) {
        return;
      }

      if (mediaQuery.matches) {
        this.mainCarousel.go(index);
      } else {
        document.getElementById(`image-${index}`).scrollIntoView();
      }
    });
  }
}
