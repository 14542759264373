import CartEmpty from './CartEmpty';
import CartItem from './CartItem';
import CartTotals from './CartTotals';
import FocusTrap from 'focus-trap-react';

import cn from 'classnames';
import { createPortal } from 'preact/compat';
import { getTranslation } from '../helpers/utilities';
import { h } from 'preact';
import { useCartContext } from '../context/CartContext';

const MiniCartDrawerEl = document.getElementById('preact-mini-cart-drawer');

const MiniCart = () => {
  const { cart, isMiniCartOpen, toggleMiniCart, miniCartData } =
    useCartContext();

  const { contentForAdditionalButtons, additionalCheckoutButtons } =
    miniCartData.settings;

  return createPortal(
    <FocusTrap active={isMiniCartOpen}>
      <div
        class={cn(
          'fixed inset-0',
          isMiniCartOpen
            ? 'opacity-1 visible'
            : `invisible opacity-0 delay-300`,
        )}
      >
        <div
          onClick={() => toggleMiniCart(false)}
          class={cn(
            `absolute inset-0 bg-primary transition-opacity duration-300`,
            isMiniCartOpen ? 'opacity-50' : 'opacity-0',
          )}
        />
        <div
          class={cn(
            `absolute top-0 bottom-0 right-0 z-50 flex w-full max-w-xl flex-col bg-white px-4 pt-2 pb-6 transition-transform duration-300 lg:px-6`,
            isMiniCartOpen ? 'translate-x-0' : 'translate-x-full',
          )}
        >
          <div class="pb-6 text-center">
            <div class="mb-2 flex items-center justify-end">
              <button onClick={() => toggleMiniCart(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 40 40"
                  fill="none"
                  class="h-10 w-10"
                >
                  <path
                    d="M12.93 12.07 20 19.14m0 0-7.07 7.07M20 19.14l7.07 7.07M20 19.14l7.07-7.07"
                    stroke="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div
            class="flex flex-col-reverse overflow-auto"
            data-cart-items
            data-scroll-lock-scrollable
          >
            {cart.items && cart.items.length > 0 ? (
              cart.items.map(item => <CartItem item={item} key={item.key} />)
            ) : (
              <CartEmpty />
            )}
          </div>

          <div class="mt-auto">
            {cart.total_price > 0 && <CartTotals cart={{ ...cart }} />}

            <form
              class="mx-auto mt-4 max-w-2xl"
              action={`${Shopify.routes.root}cart`}
              method="POST"
            >
              <button
                type="submit"
                name="checkout"
                class="button button--dark button--block"
              >
                {getTranslation('mini_cart.checkout')}
              </button>

              {additionalCheckoutButtons && (
                <div
                  class="additional-checkout-buttons additional-checkout-buttons--vertical"
                  dangerouslySetInnerHTML={{
                    __html: contentForAdditionalButtons,
                  }}
                ></div>
              )}
            </form>
          </div>
        </div>
      </div>
    </FocusTrap>,
    MiniCartDrawerEl,
  );
};

export default MiniCart;
