import Scroll from '../helpers/scroll';
import Search from '../components/search/Search';
import scrollLock from 'scroll-lock';

const selectors = {
  headerNavigation: '[data-header-navigation]',
  headerNavDetails: '[data-header-nav-details]',
  headerBackdrop: '[data-header-backdrop]',
  navHamburger: '[data-nav-hamburger]',
  navClose: '[data-nav-close]',
  announcementBar: '[data-announcement-bar]',
};

export default class MainHeader {
  constructor(section) {
    this.section = section;
    new Search(section);
    const { settings } = section.data;

    if (settings.enable_fixed_header) {
      this.listener = new Scroll(this.onScroll, this.section.el);
      this.section.el.className +=
        ' sticky top-0 z-50 bg-white transition-transform';
    }

    this.headerNavigation = section.el.querySelector(
      selectors.headerNavigation,
    );
    this.headerBackdrop = section.el.querySelector(selectors.headerBackdrop);

    // this.initNavigation();
    this.initMobileNavigation();
  }

  onScroll = direction => {
    if (direction === 'up') {
      this.showMenu();
    } else if (direction === 'down') {
      this.hideMenu();
    }
  };

  showMenu = () => {
    this.section.el.classList.remove('-translate-y-full');
  };

  hideMenu = () => {
    this.section.el.classList.add('-translate-y-full');
  };

  initNavigation = () => {
    const linkDetails = this.section.el.querySelectorAll(
      selectors.headerNavDetails,
    );
    document.addEventListener('click', e => {
      linkDetails.forEach(link => {
        if (e.target !== link && !link.contains(e.target)) {
          link.removeAttribute('open');
        }
      });
    });
  };

  openNav = () => {
    this.headerNavigation.classList.add('translate-x-full');
    this.headerBackdrop.classList.remove('opacity-0', 'invisible');
    scrollLock.disablePageScroll();
  };

  closeNav = () => {
    this.headerNavigation.classList.remove('translate-x-full');
    this.headerBackdrop.classList.add('opacity-0', 'invisible');
    scrollLock.enablePageScroll();
  };

  initMobileNavigation = () => {
    const navHamburger = this.section.el.querySelector(selectors.navHamburger);
    const navClose = this.section.el.querySelector(selectors.navClose);
    navHamburger.addEventListener('click', () => {
      this.openNav();
    });
    navClose.addEventListener('click', () => {
      this.closeNav();
    });
    this.headerBackdrop.addEventListener('click', () => {
      this.closeNav();
    });
  };
}
