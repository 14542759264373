import Splide from '@splidejs/splide';

const selectors = {
  mainCarousel: '[data-media-carousel]',
};

export default class DynamicMediaWithText {
  constructor(section) {
    const carousel = section.el.querySelector(selectors.mainCarousel);

    if (carousel) {
      const carouselOptions = {
        type: 'fade',
        perPage: 1,
        arrows: false,
        pagination: true,
        rewind: true,
        classes: {
          pagination: 'splide__pagination absolute bottom-8 left-6',
        },
      };

      const splide = new Splide(carousel, carouselOptions).mount();
      const { Slides } = splide.Components;
      splide.on('move', slide => {
        const currentSlide = Slides.getAt(slide).slide;
        const paginationColor = currentSlide.dataset.paginationColor;

        const paginationEl = section.el.querySelector('.splide__pagination');
        if (paginationColor === 'black') {
          paginationEl.classList.add('splide__pagination--black');
        } else {
          paginationEl.classList.remove('splide__pagination--black');
        }
      });
    }
  }
}
