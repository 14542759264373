import Accordion from '../components/Accordion';
import Filters from '../components/Filters';
import ProductItem from '../components/product/ProductItem';

const selectors = {
  productItems: '[data-product-item]',
  filterForm: '[data-filter-form]',
  filterList: '[data-filter-list]',
  collectionList: '[data-collection-list]',
};

export default class MainSearch {
  constructor(section) {
    const productItems = section.el.querySelectorAll(selectors.productItems);
    const filterForms = section.el.querySelectorAll(selectors.filterForm);

    [...productItems].forEach(productItem => {
      new ProductItem(productItem);
    });

    [...filterForms].forEach(form => {
      new Filters(form);
    });

    this._initCollectionList(section);
    this._initFilterList(section);
  }

  _initCollectionList(section) {
    const el = section.el.querySelector(selectors.collectionList);

    if (!el) {
      return;
    }

    new Accordion({
      el: el,
      triggerClass: 'faq-trigger',
      contentClass: 'faq-content',
      closeOnClickOutside: false,
    });
  }

  _initFilterList(section) {
    const el = section.el.querySelector(selectors.filterList);

    if (!el) {
      return;
    }

    new Accordion({
      el: el,
      triggerClass: 'faq-trigger',
      contentClass: 'faq-content',
      closeOnClickOutside: false,
    });
  }
}
