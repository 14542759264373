import VanillaModal from 'vanilla-modal';
import focusTrap from 'focus-trap';
import scrollLock from 'scroll-lock';

const selectors = {
  drawer: '[data-pxu-drawer]',
  drawerInner: '[data-pxu-drawer-inner]',
  drawerContent: '[data-pxu-drawer-content]',
};

/**
 * Wrapper class for drawer dialogs.
 * A drawer can be created in markup using a link to a hidden element
 * (https://www.npmjs.com/package/vanilla-modal#3-create-the-modals-container-using-html)
 * or programatically, using the api
 * (https://www.npmjs.com/package/vanilla-modal#7-programmatically-opening-a-modal)
 *
 * @export
 * @class Drawer
 */
export default class Drawer {
  constructor(config = {}) {
    this.drawer = null;
    this.drawerEl = document.querySelector(selectors.drawer);
    this.drawerInner = document.querySelector(selectors.drawerInner);

    // Extend default vanilla-modal callbacks back to instantiator of Drawer
    // so that we can add additional functionality for a11y etc.
    this.config = Object.assign(
      {
        onBeforeOpen: event => {},
        onOpen: (event, drawer) => {},
        onClose: (event, drawer) => {},
        onBeforeClose: event => {},
      },
      config,
    );

    this._init();
  }

  /**
   * Create drawer instance and focusTrap
   */
  _init() {
    this.drawer = new VanillaModal({
      close: '[data-pxu-drawer-close]',
      modal: selectors.drawer,
      modalInner: selectors.drawerInner,
      modalContent: selectors.drawerContent,
      page: selectors.drawer,
      loadClass: '',
      class: '',
      clickOutside: true,
      onOpen: this._onOpen,
      onClose: this._onClose,
      onBeforeOpen: this._onBeforeOpen,
      onBeforeClose: this._onBeforeClose,
    });

    this.focusTrap = focusTrap(selectors.drawerInner, {
      onActivate: () => {
        this.drawerEl.classList.add('focus-trap');
      },
      onDeactivate: () => {
        this.drawerEl.classList.remove('focus-trap');
      },
      initialFocus: () => document.querySelector(selectors.drawerInner),
      clickOutsideDeactivates: true,
    });
  }

  /**
   * Callback that fires before drawer opens
   *
   * @param {Object} event
   */
  _onBeforeOpen = event => {
    this.config.onBeforeOpen(event);
  };

  /**
   * Callback that fires when drawer opens
   *
   * @param {Object} event
   */
  _onOpen = event => {
    this.drawerEl.setAttribute('aria-hidden', false);
    this.drawerEl.classList.remove('opacity-0', 'invisible');
    this.drawerInner.classList.remove('translate-x-full');
    this.drawerInner.classList.add('translate-x-0');
    this.focusTrap.activate();
    scrollLock.disablePageScroll();
    this.config.onOpen(event, this.drawerEl);
  };

  /**
   * Callback that fires before drawer closes
   *
   * @param {Object} event
   */
  _onBeforeClose = event => {
    this.config.onBeforeClose(event);
  };

  /**
   * Callback that fires when drawer closes
   *
   * @param {Object} event
   */
  _onClose = event => {
    this.drawerEl.setAttribute('aria-hidden', true);
    this.drawerEl.classList.add('opacity-0', 'invisible');
    this.drawerInner.classList.add('translate-x-full');
    this.drawerInner.classList.remove('translate-x-0');
    this.focusTrap.deactivate();
    scrollLock.enablePageScroll();
    this.config.onClose(event, this.drawerEl);
  };

  /**
   * Test if a drawer is open
   *
   * @returns {boolean}
   */
  isOpen() {
    return this.drawer && this.drawer.isOpen;
  }

  /**
   * Open a drawer with contents from selector
   *
   * @param selector The contents to inject
   */
  open(selector) {
    if (this.drawer && this.drawer.isOpen) {
      return;
    }

    this.drawer.open(selector);
  }

  /**
   * Close a drawer
   */
  close() {
    this.drawer.close();
  }

  /**
   * Garbage collection
   */
  unload() {
    if (!this.drawer || !this.drawer.isOpen) {
      return;
    }

    this.drawer.destroy();
  }
}
