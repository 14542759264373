import ProductItem from '../components/product/ProductItem';
import initCarousel from '../helpers/carousel';

const selectors = {
  productItem: '[data-product-item]',
};

export default class DynamicFeaturedCollection {
  constructor(section) {
    // Init product items
    const productItems = section.el.querySelectorAll(selectors.productItem);
    [...productItems].forEach(productItem => {
      new ProductItem(productItem);
    });

    initCarousel(section.el);
  }
}
